body {
  font-family: 'Roboto', sans-serif;
  background-color: #272c30;
  color: #fff;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  align-items: center; /* Align items vertically */
  width: 100%;
  height: 5%;
  background-color: #333;
  color: #fff; /* Ensure text is white */
  position: fixed; /* Fix the header at the top */
  top: 0;
  z-index: 1000; /* Ensure it is above other content */
  font-family: 'Roboto', sans-serif;
}

/* .company_header, .page-header {
  flex-grow: 1;
  text-align: center;
}

.company_header a, .page-header a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
} */

.company_header a{
  margin-left: 20px; /* Adjust spacing as needed */
  display: flex;
  text-decoration: none;
  color: #fff;
  align-items: center;
}
.company_header {
  
  margin-left: 20px; /* Adjust spacing as needed */
  display: flex;
  text-decoration: none;
  color: #fff;
  align-items: center;
}

.page-header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  align-items: center; /* Ensure vertical alignment */
}
.page-header h4 {
  margin: 0; /* Remove default margin */
}

.sidebar_button {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Ensure some space between the button and the company header */
}

#btn_sidebar {
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
}

.sidebar {
  position: fixed;
  top: 50px; /* Adjust this value based on the height of the header */
  left: 0;
  height: calc(100% - 50px); /* Adjust this value based on the height of the header */
  width: 250px;
  background-color: #333;
  padding-top: 20px;
  transform: translateX(-250px);
  transition: transform 0.3s ease;
  z-index: 999; /* Ensure sidebar is below header */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  display: block;
}
.image-container {
  margin-bottom: 2vh;
}

.globe-image {
  width: 30vh; /* Adjust the size as needed */
  height: auto;
}

.video-form {
  display: flex;
  align-items: center;
}

.video-input {
  padding: 0.5vh;
  margin-right: 0.5vh;
  border: none;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: white;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 70px;
}

.spacing {
  margin-top: 3vh; /* Adjust the value as needed */
}

#content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%; /* Center content and keep it within a reasonable width */
  margin: 0 auto;
}

#transcription-container {
  width: 50%;  /* Set width to 50% */
  padding: 1vh;
  background-color: #272c30;
  border: 0.1vh solid #f5f2f2;
  box-shadow: 0 0 1vh rgba(0, 0, 0, 0.5);
  max-height: 40vh; /* Ensure the container has the same max height */
  min-height: 40vh; /* Ensure the container has the same min height */
  overflow-y: auto;
  text-align: justify;  /* Justify text */
  border-radius: 1.5vh; /* Add rounded edges */
}

#hw-score-plot {
  width: 60%; /* Ensure plot has the same width as transcription container */
  margin-left: 20px;  /* Add some spacing */
  max-height: 30vh; /* Ensure the plot has the same max height */
  min-height: 30vh; /* Ensure the plot has the same min height */
}

#qa-section {
  margin-top: 80px;
  color: white;
  text-align: center;
}

#qa-section form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#qa-section input[type="text"] {
  padding: 10px;
  width: 100vh;
}

#qa-section button {
  padding: 10px 20px;
}

#live-video-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100vh; /* Maximum width of the iframe */
  margin: auto; /* Center the iframe */
}

#live-video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

#live-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#answer-container {
  width: 60%; 
  padding: 1vh;
  background-color: #272c30;
  max-height: 40vh; /* Ensure the container has the same max height */
  min-height: 40vh; /* Ensure the container has the same min height */
  overflow-y: auto;
  text-align: left;  /* Justify text */
}